import { Dim, DimType } from "src/pages/DrawingViewer/interfaces"
import { getDatums, isBasic, isReference } from "src/pages/DrawingViewer/util/dim"

export const getDimDescription = (dim: Dim): string => {
  let description = getDimTypeDescription(dim)
  description += getDimTolMod2Description(dim)
  description += getDimDatumsDescription(dim)
  description += getDimTolTypeDescription(dim)
  description += getDimTextDescription(dim)
  return description
}

const getDimTypeDescription = (dim: Dim): string => {
  if (dim.DimType in DimType) {
    const label = DimType[dim.DimType].replace(/([A-Z])/g, " $1")
    return label.charAt(0).toUpperCase() + label.slice(1)
  }
  return `Unrecognized DimType: ${dim.DimType}`
}

const getDimTolMod2Description = (dim: Dim): string => {
  if (dim.DimTolMod2 === "Ì") return " at Maximum Material Condition"
  if (dim.DimTolMod2 === "Ë") return " at Least Material Condition"
  return ""
}

const getDimDatumsDescription = (dim: Dim): string => {
  const datums = getDatums([dim])
  if (datums.length === 0) return ""
  if (datums.length === 1) {
    return ` relative to ${datums[0]}`
  }
  if (datums.length === 2) {
    return ` relative to ${datums[0]} and ${datums[1]}`
  }
  return ` relative to ${datums.slice(0, -1).join(", ") + ` and ${datums[datums.length - 1]}`}`
}

const getDimTolTypeDescription = (dim: Dim): string => {
  if (isBasic([dim])) return " (Basic Dimension)"
  if (isReference([dim])) return " (Reference Dimension)"
  return ""
}

const getDimTextDescription = (dim: Dim): string => {
  if (dim.DimText !== "") return ` (${dim.DimText})`
  return ""
}
