import React, { FC, lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { batch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Callout,
  Checkbox,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Spinner,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { useCurrentUserData } from "@subscale/formlogic-auth-ts"
import { AxiosError } from "axios"

import { TaskStatus } from "src/client-axios"
import { PartModelUpload } from "src/components/Cam/TreeNavigator/ConfigPanel/Panels/DesignConfigPanel/DesignConfigPanel"
import { ViewerModal } from "src/components/Cam/ViewerModal/ViewerModal"
import { AbbreviatedLabel } from "src/components/Generic/AbbreviatedLabel/AbbreviatedLabel"
import { ContextTarget } from "src/components/Generic/ContextTarget/ContextTarget"
import { Modal } from "src/components/Generic/Modal/Modal"
import { isItar } from "src/components/ItarBar/ItarBar"
import { DownloadButton } from "src/components/Shared/DownloadButton/DownloadButton"
import { PrioritySelector } from "src/components/Shared/PrioritySelector/PrioritySelector"
import { sendEvent, useWebsocketMessageListener } from "src/components/Websocket/Websocket"
import {
  CamJobDetailsFragment,
  Priority,
  useCamJobDetailsQuery,
  useCamJobFilesQuery,
  useCamJobsByIdsQuery,
  useCamJobUriDetailsQuery,
  useTaskProgressQuery,
  useUpdateCamJobActivePlanMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import { useJobBackupDownloader } from "src/hooks/useArchiveDownloader"
import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"
import { useGetDimsData } from "src/pages/DrawingViewer/useGetDimsData"
import { JobFiles } from "src/pages/JobOverview/JobFiles/JobFiles"
import { PopulateConfig } from "src/pages/ViewerPage/PopulateConfig"
import { activeActions, activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { storedPlanThunks } from "src/store/cam/storedPlanThunks"
import { useAppDispatch } from "src/store/rootStore"
import { viewerModalActions, ViewerModalMode } from "src/store/ui/viewerModal"
import { DrawingViewer } from "../DrawingViewer/DrawingViewer"
import { RestoreUpload } from "../Jobs/RestoreUpload/RestoreUpload"
import { ControlsHelper } from "../ViewerPage/Cards/ControlsHelper/ControlsHelper"
import MaterialLabel from "./MaterialLabel/MaterialLabel"
import {
  NotesEditorButton,
  NotesEditorEntry,
  useNotesEditor,
} from "./OverviewTabPanel/NotesEditor/NotesEditor"
import { OverviewTabPanel } from "./OverviewTabPanel/OverviewTabPanel"
import { PlanProposalsTabPanel } from "./PlanProposalsTabPanel/PlanProposalsTabPanel"

import styles from "./JobOverview.module.css"

const FileModal = lazy(
  () => import(/* webpackChunkName: "FileModal" */ "./OperationCard/FileModal/FileModal")
)

const JobLabel: FC<{
  jobId: string
  setRestorePlanModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ jobId, setRestorePlanModalIsOpen }) => {
  const { data } = useCamJobDetailsQuery({
    variables: { jobId },
  })
  const toaster = useToaster()
  const { tasksApi } = useApi()
  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  const [label, setLabel] = useState<string | undefined>()
  const [exportJobIsOpen, setExportJobIsOpen] = useState(false)
  const [includeFilesTable, setIncludeFilesTable] = useState(false)
  const [includeManifests, setIncludeManifests] = useState(false)
  const [includeVericutReportFiles, setIncludeVericutReportFiles] = useState(false)

  useEffect(() => {
    if (label && label.length > 0) {
      document.title = `RS: ${label}`
    }

    return () => {
      document.title = "Formlogic RemoteShop"
    }
  }, [label])

  const generateJobBackup = useJobBackupDownloader()

  useEffect(() => {
    setLabel(data?.camJob?.label)
  }, [data])

  const handleBackupJob = (
    includeFiles: boolean,
    includeManifests: boolean,
    includeVericutReportFiles: boolean
  ) => {
    generateJobBackup(
      jobId,
      undefined,
      includeFiles,
      includeManifests,
      includeVericutReportFiles,
      undefined
    )
  }

  const handleBackupPlan = () => {
    generateJobBackup(undefined, activePlanId, undefined, undefined)
  }

  const regenerateProposals = () => {
    tasksApi.generateProposals(jobId, true).then(() => {
      sendEvent("generateProposals", { jobId, clearExistingProposals: true })
    })
  }

  const regenerateThumbnail = () => {
    tasksApi
      .regenerateThumbnail(jobId)
      .then(() => {
        sendEvent("regenerateThumbnail", { jobId })
      })
      .catch(e => {
        console.error(e)
        toaster.show({ message: "Failed to regenerate thumbnail", intent: "danger" })
      })
  }

  const jobRevision = data?.camJob?.jobRevision

  return (
    <div className={styles.jobLabelContainer}>
      <ContextTarget
        menu={
          <Menu>
            <MenuItem text={"Export Job"} onClick={() => setExportJobIsOpen(true)} />
            <MenuDivider />
            <MenuItem text={"Export Active Plan"} onClick={handleBackupPlan} />
            <MenuItem text={"Import Plan"} onClick={() => setRestorePlanModalIsOpen(true)} />
            <MenuDivider />
            <MenuItem text={"Regenerate Proposals"} onClick={regenerateProposals} />
            <MenuItem text={"Regenerate Thumbnail"} onClick={regenerateThumbnail} />
          </Menu>
        }
      >
        <div className={styles.partName}>
          Part:&nbsp;
          {label && <AbbreviatedLabel label={label} maxLength={35} />}
        </div>
      </ContextTarget>
      {jobRevision && jobRevision > 1 && (
        <div className={`${Classes.TEXT_MUTED} ${styles.partRevision}`}>rev.{jobRevision}</div>
      )}

      <Dialog
        className={styles.dialog}
        icon={"info-sign"}
        onClose={() => setExportJobIsOpen(!exportJobIsOpen)}
        title={`Export job`}
        isOpen={exportJobIsOpen}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        enforceFocus={true}
        usePortal={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <Checkbox
            checked={includeFilesTable}
            label={"Include files table"}
            onChange={_ => setIncludeFilesTable(!includeFilesTable)}
          />
          <Checkbox
            checked={includeManifests}
            label={"Include manifests"}
            onChange={_ => setIncludeManifests(!includeManifests)}
          />
          <Checkbox
            checked={includeVericutReportFiles}
            label={"Include vericut report dirs"}
            onChange={_ => setIncludeVericutReportFiles(!includeVericutReportFiles)}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                handleBackupJob(includeFilesTable, includeManifests, includeVericutReportFiles)
                setExportJobIsOpen(false)
              }}
              intent="primary"
            >
              Export
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const JobCustomerCode: FC<{ jobId: string }> = ({ jobId }) => {
  const { data } = useCamJobDetailsQuery({
    variables: { jobId },
  })

  const [code, setCode] = useState<string | undefined>()

  useEffect(() => {
    setCode(data?.camJob?.customerCodename)
  }, [data])

  return (
    <div className={styles.customerCodename}>
      Customer:&nbsp;
      <div>{code}</div>
    </div>
  )
}

const TaskInfo: FC<{ jobId: string; planIds?: string[] }> = ({ jobId, planIds }) => {
  const dispatch = useAppDispatch()

  const { plansApi } = useApi()

  const { data: taskProgressData, refetch } = useTaskProgressQuery({ variables: { jobId } })

  const { refetch: jobRefetch } = useCamJobDetailsQuery({
    variables: { jobId },
  })

  const taskProgress = taskProgressData?.taskProgresses?.nodes.find(progress =>
    progress.name.endsWith("generate_proposals_task")
  )

  const handleCreatePlanClick = () => {
    sendEvent("createEmptyPlan")
    plansApi.createPlan(jobId).then(response => {
      jobRefetch()
      setTimeout(() => {
        dispatch(activeActions.setActivePlanId(response.data))
      }, 500)
    })
  }
  useWebsocketMessageListener(wsMessage => {
    if (
      wsMessage.type === "TaskProgress" &&
      wsMessage.jobId === jobId &&
      wsMessage.name.endsWith("generate_proposals_task")
    ) {
      refetch()
      jobRefetch()
    }
  })

  if (planIds?.length === 0) {
    if (taskProgress?.status === "ERROR") {
      return (
        <NonIdealState
          title={"Plan Generation Failed"}
          description={"There was an error generating plans."}
          icon={"error"}
        >
          <Button icon="small-plus" intent={"primary"} onClick={handleCreatePlanClick}>
            Create Empty Plan
          </Button>
          {taskProgress.message && (
            <>
              <pre className={"bp3-code-block " + styles.generationErrorMessage}>
                <strong>Error: </strong>
                {taskProgress.message}
              </pre>
            </>
          )}
        </NonIdealState>
      )
    } else if (taskProgress?.status === "PROCESSING" || taskProgress?.status === "PENDING") {
      return <Spinner />
    } else {
      if (taskProgressData !== undefined) {
        return (
          <NonIdealState
            title={"No Plans Generated"}
            description={
              "No plans have been successfully generated automatically.  Please create an empty plan to continue."
            }
            icon={"warning-sign"}
          >
            <Button icon="small-plus" onClick={handleCreatePlanClick}>
              Create Empty Plan
            </Button>
          </NonIdealState>
        )
      }
    }
  }

  return <></>
}

const JobOverview: FC = () => {
  const { jobId, jobUri } = useParams<{ jobId?: string; jobUri?: string }>()
  const params = new URLSearchParams(window.location.search)

  const history = useHistory()

  const { data } = useCamJobsByIdsQuery({
    variables: { ids: [jobId || ""] },
  })
  if (jobId) {
    const nodes = data?.camJobs?.nodes
    if (nodes !== undefined) {
      if (nodes.length > 0) {
        const paramsString = params.toString()
        const queryParams = paramsString.length > 0 ? `?${paramsString}` : ``
        history.push(`/jobs/${nodes[0].uri}${queryParams}`)
      } else {
        return <NonIdealState title="No Job Found" />
      }
    } else {
      return (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )
    }
  }

  if (jobUri) {
    return <JobUriOverview jobUri={jobUri} />
  }

  return <></>
}

export default JobOverview

const JobUriOverview: FC<{ jobUri: string }> = ({ jobUri }) => {
  const { data } = useCamJobUriDetailsQuery({
    variables: { jobUri },
  })

  const currentUserData = useCurrentUserData()

  const activeRevision = useSelector(activeSelectors.selectActiveRevison)
  const dispatch = useAppDispatch()

  const params = new URLSearchParams(window.location.search)

  const revParam = params.get("revision")
  const revision = revParam != null ? +revParam : undefined

  const locked =
    revision !== undefined || (currentUserData?.permissions.includes("readonly:plan") ?? false)

  useEffect(() => {
    if (revision !== undefined && activeRevision !== revision) {
      dispatch(activeActions.setActiveRevision(revision))
    }
  }, [revision, activeRevision, dispatch])

  const nodes = data?.camJobs?.nodes

  if (nodes !== undefined) {
    if (nodes.length > 0) {
      return (
        <JobIdOverview jobId={nodes[0].id} revision={revision} locked={locked} jobUri={jobUri} />
      )
    } else {
      return <NonIdealState title="No Job Found" />
    }
  } else {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    )
  }
}

export const JobIdOverview: FC<{
  jobId: string
  planId?: string
  locked?: boolean
  revision?: number
  jobUri?: string
}> = ({ jobId, planId, locked, revision, jobUri }) => {
  const dispatch = useAppDispatch()
  const { planchangerApi } = useApi()

  const [showFiles, setShowFiles] = useState(false)
  const [nPlanIds, setNPlanIds] = useState(0)
  const resetCallbackRef = useRef<() => void | undefined>()
  const [restorePlanModalIsOpen, setRestorePlanModalIsOpen] = useState<boolean>(false)
  const [planIds, setPlanIds] = useState<string[] | undefined>(undefined)
  const [showDrawing, setShowDrawing] = useState(false)

  const enableDrawingButton = useGetDimsData().drawingId !== undefined

  const { data: jobFilesData } = useCamJobFilesQuery({ variables: { jobId } })

  const { data, stopPolling, refetch: refetchCamJobDetails } = useCamJobDetailsQuery({
    variables: { jobId },
    pollInterval: 1000,
  })
  const { data: taskProgressData } = useTaskProgressQuery({ variables: { jobId } })

  const numFiles = jobFilesData?.camJobFiles?.nodes.length ?? 0

  useEffect(() => {
    dispatch(activeActions.setActiveJobId(jobId))
    sendEvent("overviewOpened", { jobId })
  }, [dispatch, jobId])

  useEffect(() => {
    batch(() => {
      dispatch(activeActions.setActiveJobLabel(data?.camJob?.label))
      dispatch(viewerModalActions.setViewerModalJobId(data?.camJob?.id))
      dispatch(viewerModalActions.setViewerModalModelId(data?.camJob?.model?.id))
    })
  }, [data, dispatch])

  const finishedProposals =
    taskProgressData?.taskProgresses?.nodes.some(progress =>
      progress.name.endsWith("generate_proposals_task")
    ) ?? false

  useEffect(() => {
    if (planId) {
      setPlanIds([planId])
    } else if (data?.camJob?.plans.length || finishedProposals) {
      stopPolling()
      setPlanIds(data?.camJob?.plans.map(plan => plan.id) || [])
    } else if (data?.camJob?.plans) {
      setPlanIds([])
    }
  }, [planId, data, finishedProposals, stopPolling])

  // TODO: Store job data in redux store so this function is unnecessary
  useEffect(() => {
    if (planIds === undefined) return
    if (nPlanIds !== planIds.length) {
      dispatch(storedPlanThunks.fetchPlansByIds({ planIds, planchangerApi, revision }))

      setNPlanIds(planIds.length)
    }
  }, [planIds, nPlanIds, revision, setNPlanIds, dispatch, planchangerApi])

  const { editable, setEditable, onClickRef } = useNotesEditor()

  const openModal = () => {
    batch(() => {
      dispatch(viewerModalActions.setViewerModalIsOpen(true))
      dispatch(viewerModalActions.setViewerModalFilterPlans(false))
      dispatch(viewerModalActions.setViewerModalModelId(data?.camJob?.model?.id))
      dispatch(viewerModalActions.setViewerModalJobId(data?.camJob?.id))
    })
  }

  const handleClickViewerModal = (mode: ViewerModalMode) => {
    dispatch(viewerModalActions.setViewerModalMode(mode))
    openModal()
  }

  const portalUrl = `https://portal.${isItar() ? "itar." : ""}formlogic.com/parts/${
    data?.camJob?.label
  }`

  return (
    <>
      {revision && <div className={styles.readonlyView}>Viewing Job at Revision #{revision}</div>}
      <ControlsHelper className={styles.controlsCard} resetCallbackRef={resetCallbackRef} />
      <PopulateConfig />
      <Suspense fallback={<></>}>
        <FileModal />
      </Suspense>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <div className={styles.headerLeftTop}>
              <JobLabel jobId={jobId} setRestorePlanModalIsOpen={setRestorePlanModalIsOpen} />
              <div>
                <Tooltip2
                  placement={"top"}
                  content={"Download part model"}
                  openOnTargetFocus={false}
                >
                  <DownloadButton
                    locator={data?.camJob?.model?.source?.locator}
                    className={styles.downloadButton}
                  >
                    <AnchorButton icon="import" minimal />
                  </DownloadButton>
                </Tooltip2>
              </div>

              <MaterialLabel jobId={jobId} />

              <JobCustomerCode jobId={jobId} />
              {data?.camJob?.priority &&
                [Priority.Low, Priority.High].includes(data?.camJob?.priority) && (
                  <div className={styles.priorityContainer}>
                    <PrioritySelector camJob={data?.camJob} label="Priority: " />
                  </div>
                )}
            </div>
            <div className={styles.jobButtons}>
              <Button
                className={styles.partViewButton}
                onClick={() => handleClickViewerModal(ViewerModalMode.Part)}
                intent={"primary"}
              >
                View Part
              </Button>
              <Tooltip2
                content={
                  <div>
                    A bubbled drawing has not yet been uploaded for this part.
                    <br />
                    <br />
                    If needed, please contact the Quality Team on #inspection.
                  </div>
                }
                disabled={enableDrawingButton}
              >
                <AnchorButton
                  disabled={!enableDrawingButton}
                  className={styles.partViewButton}
                  onClick={() => setShowDrawing(true)}
                  intent={enableDrawingButton ? "primary" : "none"}
                >
                  View Drawing
                </AnchorButton>
              </Tooltip2>
              <Button
                className={styles.exportJobButton}
                intent={!showFiles && numFiles > 0 ? "primary" : "none"}
                onClick={() => setShowFiles(!showFiles)}
              >
                {showFiles
                  ? "Hide Files"
                  : `Show ${numFiles > 0 ? ` ${numFiles}` : ""} File${numFiles !== 1 ? "s" : ""}`}
              </Button>
              <NotesEditorButton
                editable={editable}
                onClick={() => onClickRef.current?.(editable)}
                locked={locked}
              />
              <AnchorButton
                className={styles.qualityInfoButton}
                intent={"primary"}
                target="_blank"
                href={portalUrl}
              >
                Quality Info
              </AnchorButton>
              {data?.camJob?.ppartId && (
                <AnchorButton
                  className={styles.partViewButton}
                  href={`https://app.paperlessparts.com/parts/viewer/${data.camJob.ppartId}`}
                  target="_blank"
                >
                  View in Paperless Parts
                </AnchorButton>
              )}
            </div>
            {showFiles && (
              <>
                <JobFiles jobId={jobId} />
                <Divider className={styles.partPlanSelectorDivider} />
              </>
            )}
            <NotesEditorEntry
              jobId={jobId}
              onClickRef={onClickRef}
              editable={editable}
              setEditable={setEditable}
            />
            <Divider className={styles.partPlanSelectorDivider} />
            {data?.camJob && (
              <PlansTabs
                camJob={data?.camJob}
                onJobDetailsChange={() => refetchCamJobDetails()}
                locked={locked}
                revision={revision}
                jobUri={jobUri}
              />
            )}
          </div>
        </div>
      </div>
      <ViewerModal locked={locked} />
      <Modal
        isOpen={restorePlanModalIsOpen}
        headerText={"Import Previously-Exported Plan"}
        handleRequestClose={e => {
          e.stopPropagation()
          setRestorePlanModalIsOpen(false)
        }}
        bodyComponent={
          <RestoreUpload
            jobId={data?.camJob?.id}
            isPlan
            setRestoreModalIsOpen={setRestorePlanModalIsOpen}
          />
        }
      />
      {planIds === null && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
      <TaskInfo jobId={jobId} planIds={planIds} />

      {showDrawing && (
        <Dialog
          icon={"info-sign"}
          onClose={() => setShowDrawing(false)}
          title={"Drawing"}
          isOpen={showDrawing}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          className={styles.drawingDialog}
        >
          <div className={styles.drawingContainer}>
            <DrawingViewer inDialog showInspection allGreenCheckMarks />
          </div>
        </Dialog>
      )}
    </>
  )
}

// TODO: Not sure if this regex is needed with new short plan label format

const uriRegex = /\d{4}\/(?<month>\d{2})\/(?<day>\d{2})\/[^/]+\/[^/]+\/p(?<number>\d+)/u

export const shortLabel = (val: string): string => {
  const matches = val.match(uriRegex)

  if (matches !== null) {
    let shortVal = matches.groups?.month + "/" + matches.groups?.day

    const planNumber = matches.groups?.number

    if (planNumber !== undefined && +planNumber > 1) {
      shortVal += String.fromCharCode(+planNumber + 96)
    }

    return shortVal
  } else {
    return val
  }
}

export const shortPlanLabel = (val: string | undefined): string | undefined => {
  return val?.split("/")?.length === 5 ? val?.split("/")?.[4] : val
}

const PlansTabs: FC<{
  camJob: CamJobDetailsFragment
  onJobDetailsChange: () => void
  locked?: boolean
  revision?: number
  jobUri?: string
}> = ({ camJob, onJobDetailsChange, locked, revision, jobUri }) => {
  const dispatch = useAppDispatch()
  const [updateCamJobActivePlanMutation] = useUpdateCamJobActivePlanMutation()
  const { plansApi } = useApi()
  const { refetch: jobRefetch } = useCamJobDetailsQuery({
    variables: { jobId: camJob.id },
  })
  const handleTabChange = useCallback(
    (newTabId: TabId) => {
      dispatch(activeActions.setActivePlanId(String(newTabId)))
    },
    [dispatch]
  )

  const storedPlanEntities = useSelector(storedPlansSelectors.selectStoredPlanEntities)

  const activePlanId = useSelector(activeSelectors.selectActivePlanId)

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(activeActions.setActivePlanId(undefined))
        dispatch(activeActions.setActiveJobId(undefined))
        dispatch(activeActions.setActiveJobLabel(undefined))
      })
    }
  }, [dispatch])

  useEffect(() => {
    const handleSetActivePlan = (planId: string) => {
      updateCamJobActivePlanMutation({
        variables: {
          id: camJob.id,
          activePlanId: planId,
        },
        refetchQueries: ["camJobDetails"],
      })
    }

    const nonProposalPlans = camJob.plans.filter(({ isProposal }) => !isProposal) || []
    const possiblePlanIds = ["new-plan", ...camJob.plans.map(({ id }) => id)]
    const activePlanIdExists = possiblePlanIds.some(id => id === activePlanId)

    if (!camJob.activePlanId && nonProposalPlans[0]?.id) {
      handleSetActivePlan(nonProposalPlans[0]?.id)
    }

    const newTab =
      (activePlanIdExists && activePlanId) || camJob?.activePlanId || nonProposalPlans[0]?.id
    if (newTab !== activePlanId && activePlanId !== undefined) {
      handleTabChange(newTab)
    }
  }, [camJob, activePlanId, handleTabChange, updateCamJobActivePlanMutation])

  const planNodes = useMemo(() => {
    return camJob?.plans
      .filter(plan => !plan.isProposal)
      .sort((a, b) => {
        if (a.id === camJob.activePlanId) {
          return -1
        } else if (b.id === camJob.activePlanId) {
          return 1
        } else {
          const aLabel = shortPlanLabel(storedPlanEntities[a.id]?.plan.label) ?? `Plan ${1}`
          const bLabel = shortPlanLabel(storedPlanEntities[b.id]?.plan.label) ?? `Plan ${2}`
          if (aLabel < bLabel) return -1
          if (aLabel > bLabel) return 1
          return 0
        }
      })
  }, [camJob, storedPlanEntities])

  const handleCreatePlanClick = () => {
    sendEvent("createEmptyPlan")
    plansApi.createPlan(camJob.id).then(response => {
      jobRefetch()
      setTimeout(() => {
        dispatch(activeActions.setActivePlanId(response.data))
      }, 500)
    })
  }

  useEffect(() => {
    if (planNodes === undefined || planNodes.length === 0) return
    if (activePlanId) return // already initialized
    // Make a stateful selection of the initial plan on component mount.
    dispatch(activeActions.setActivePlanId(planNodes[0].id))
  }, [dispatch, planNodes, activePlanId])

  return (
    <>
      <h4 className={"bp3-heading"} style={{ textAlign: "left" }}>
        Plan Selection:
      </h4>
      <FormGroup
        contentClassName={styles.formGroupContent}
        className={styles.formGroup}
        label={<></>}
        labelInfo={
          <>
            (<Icon icon={"small-tick"} /> active manufacturing plan)
          </>
        }
      >
        <div className={styles.buttonsContainer}>
          <ButtonGroup className={styles.planButtons}>
            {planNodes.map((plan, index) => {
              const planId = plan.id
              const isActivePlan = camJob.activePlanId === planId
              const planLabel: string =
                storedPlanEntities[plan.id]?.plan.label ?? `Plan ${index + 1}`
              return (
                <Button
                  key={planId}
                  active={activePlanId === planId}
                  icon={isActivePlan ? "small-tick" : undefined}
                  onClick={() => handleTabChange(planId)}
                >
                  {shortPlanLabel(planLabel)}
                </Button>
              )
            })}
          </ButtonGroup>

          <ButtonGroup>
            <Button icon="small-plus" onClick={handleCreatePlanClick} disabled={locked}>
              Create Empty Plan
            </Button>

            <Button
              icon={"add"}
              active={activePlanId === "new-plan"}
              intent={"success"}
              onClick={() => handleTabChange("new-plan")}
              disabled={locked}
            >
              Generated Plan
            </Button>
          </ButtonGroup>
        </div>
      </FormGroup>
      <Divider className={styles.planSelectorPlanDivider} />
      <Tabs
        renderActiveTabPanelOnly
        id="planTabs"
        onChange={handleTabChange}
        selectedTabId={activePlanId}
      >
        {planNodes.map(plan => {
          const planId = plan.id
          return (
            <Tab
              id={planId}
              panel={
                <OverviewTabPanel
                  assignees={plan.assignees}
                  planId={planId}
                  camJob={camJob}
                  onJobDetailsChange={onJobDetailsChange}
                  locked={locked}
                  revision={revision}
                  jobUri={jobUri}
                />
              }
              key={planId}
            />
          )
        })}
        <Tab
          id="new-plan"
          panel={
            <PlanProposalsTabPanel
              jobId={camJob?.id}
              camJob={camJob}
              onJobDetailsChange={() => onJobDetailsChange()}
            />
          }
        />
      </Tabs>
    </>
  )
}

export const UpdatePartModelButton: FC<{
  jobId: string
  planId: string
  operationIdx: number
}> = ({ jobId, planId, operationIdx }) => {
  const onNetworkError = useNetworkErrorToast()

  const [showAlignPartModal, setShowAlignPartModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { tasksApi } = useApi()

  const [taskId, setTaskId] = useState<string | undefined>()

  useWebsocketMessageListener(wsMessage => {
    if (taskId !== undefined) {
      if (
        wsMessage.type === "TaskProgress" &&
        taskId === wsMessage.id &&
        wsMessage.status === TaskStatus.Success
      ) {
        // TODO: Update state without a reload
        window.location.reload()
      }
    }
  })

  const alignCenterModel = () => {
    setIsLoading(true)

    tasksApi
      .fitAlignmentTransform(jobId)
      .then(result => {
        setTaskId(result.data.taskId)
      })
      .catch((error: AxiosError) => onNetworkError(error, "Ingest Upload Transform Error"))
  }

  return (
    <div className={styles.updateJobButtons}>
      <Tooltip2
        hoverOpenDelay={500}
        content={
          <>
            Automatically align the CAD model with the global coordinate system axes, and translate
            its bounding box to be centered at the origin.
            <br />
            <br />
            This produces an updated model revision, and future updates to the model will retain
            this fitted transform.
          </>
        }
        openOnTargetFocus={false}
      >
        <Button onClick={() => setShowAlignPartModal(true)}>Align/Center Part Model</Button>
      </Tooltip2>
      <Dialog
        icon="outdated"
        onClose={() => setShowAlignPartModal(false)}
        title="Align/Center Part Model"
        isOpen={showAlignPartModal}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        enforceFocus={true}
        usePortal={true}
      >
        <>
          <div className={Classes.DIALOG_BODY}>
            <p>
              Automatically align the CAD model with the global coordinate system axes, and
              translate its bounding box to be centered at the origin.
            </p>
            <p>
              This produces an updated model revision, and future updates to the model will retain
              this fitted transform.
            </p>
            <Callout intent={"warning"}>
              <p>This may move the part relative to current stock and fixtures.</p>
              <p>Any resulting changes cannot easily be undone.</p>
            </Callout>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setShowAlignPartModal(false)}>Cancel</Button>
              <Button intent={"warning"} disabled={isLoading} onClick={() => alignCenterModel()}>
                Align/Center Part Model
              </Button>
            </div>
          </div>
        </>
      </Dialog>
      <PartModelUpload operationIdx={operationIdx} planId={String(planId)} />
    </div>
  )
}
