import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import { useCamJobFilesLazyQuery, useGetDrawingLazyQuery } from "src/graphql/generated"
import {
  Dim,
  DimBBox,
  DimsData,
  DimsDataOptions,
  Drawing,
  ShapeData,
  ShapeLocation,
} from "src/pages/DrawingViewer/interfaces"
import { dimLabel, hasDatum, isBasic, isReference } from "src/pages/DrawingViewer/util/dim"
import { activeSelectors } from "src/store/cam/active"
import { sortBy } from "src/util/sortBy"
import { bboxKey, getFilteredShapes } from "./util/dimCollection"
import { getDimsScoring } from "./difficulty"

export const useGetDimsData = (opts?: DimsDataOptions): DimsData => {
  const { drawingId, jobId } = opts ?? {}
  const activeJobId = useSelector(activeSelectors.selectActiveJobId)
  const [jobFile, { data: camJobFiles }] = useCamJobFilesLazyQuery()
  const [getDrawing, { data, loading, refetch }] = useGetDrawingLazyQuery()

  useEffect(() => {
    const maybeJob = jobId ?? activeJobId
    if (drawingId === undefined && maybeJob !== undefined) {
      jobFile({ variables: { jobId: maybeJob } })
    }
  }, [drawingId, jobId, activeJobId, jobFile])

  useEffect(() => {
    if (drawingId === undefined && camJobFiles !== undefined) {
      const camJobFilesNodes = [...(camJobFiles.camJobFiles?.nodes ?? [])]
      // camJobFiles are ordered in ascending order by creation time, so we reverse them to get the latest
      camJobFilesNodes.reverse()
      const jobFile = camJobFilesNodes.find(val => val.drawings.nodes.length > 0)
      if (jobFile !== undefined) {
        const jobDrawingId = jobFile?.drawings.nodes[0].id

        if (jobDrawingId !== undefined) {
          getDrawing({ variables: { drawingId: jobDrawingId } })
        }
      }
    }
  }, [drawingId, camJobFiles, getDrawing])

  useEffect(() => {
    if (drawingId !== undefined) {
      getDrawing({ variables: { drawingId } })
    }
  }, [drawingId, getDrawing])

  // eslint-disable-next-line
  const drawingArray: Drawing[] | undefined = (data?.drawing?.data?.drawings as any | undefined)
    ?.Table
  const highqaDrawingIdToPageIndex: Record<number, number> = useMemo(() => {
    const drawingIdToPageIndex: Record<number, number> = {}
    if (drawingArray !== undefined) {
      drawingArray.forEach(val => {
        drawingIdToPageIndex[val.ID] = val.DrawingPageNo
      })
    }
    return drawingIdToPageIndex
  }, [drawingArray])

  // eslint-disable-next-line
  const dimArray: Dim[] | undefined = (data?.drawing?.data?.dims as any | undefined)?.Table

  const allShapes = useMemo(() => {
    if (dimArray === undefined) return []
    const dimsByBbox = new Map<string, Dim[]>()
    dimArray.map(getDimBbox).forEach(({ dim, bbox }) => {
      const key = bboxKey(bbox)
      const existing = dimsByBbox.get(key)
      if (existing !== undefined) {
        existing.push(dim)
        dimsByBbox.set(key, existing)
      } else {
        dimsByBbox.set(key, [dim])
      }
    })

    let shapes = Array.from(dimsByBbox.values()).map((dims, i) => {
      // Can safely assume that dims has positive length based on logic used to build dimArray
      return {
        shapeId: i,
        label: dimLabel(dims),
        location: getShapeLocation(dims[0], highqaDrawingIdToPageIndex),
        dims,
        scoring: getDimsScoring(dims),
        isBasic: isBasic(dims),
        isReference: isReference(dims),
        hasDatum: hasDatum(dims),
      }
    })

    shapes = getSortedShapes(shapes)

    // Re-assign IDs in ascending order based on the sort
    shapes.map((shape, i) => {
      shape.shapeId = i + 1
      return shape
    })
    return shapes
  }, [dimArray, highqaDrawingIdToPageIndex])

  const filteredShapes = useMemo(() => {
    if (!opts?.filters) return allShapes
    return getFilteredShapes(allShapes, opts.filters)
  }, [allShapes, opts?.filters])

  return {
    drawingId: data?.drawing?.id,
    drawingFileId: data?.drawing?.camJobFile?.fileId,
    drawingLocator: data?.drawing?.camJobFile?.file?.locator,
    jobLabel: data?.drawing?.camJobFile?.job?.label,
    allShapes,
    filteredShapes,
    refetch,
    loading,
  }
}

export const getSortedShapes = (shapes: ShapeData[]): ShapeData[] => {
  let sortedShapes = shapes

  // First, sort by the DimSort from HighQA
  sortedShapes = sortBy(sortedShapes, shape => shape.dims[0]?.DimSort ?? "")

  // Next, sort by the bucketed safety factor
  sortedShapes = sortBy(sortedShapes, shape => {
    // round safety factor to nearest 0.1
    let sortingSafetyFactor = +shape.scoring.safetyFactor.toFixed(1)

    // if the safety factor is above a lower threshold, round it to a multiple
    const roundingMultiple = 2.0
    const lowerThreshold = roundingMultiple * 3
    if (sortingSafetyFactor > lowerThreshold) {
      sortingSafetyFactor = roundingMultiple * Math.round(sortingSafetyFactor / roundingMultiple)
    }

    // don't distinguish between items with safetyFactor above an upper threshold
    const upperThreshold = 12.0
    return Math.min(sortingSafetyFactor, upperThreshold)
  })

  // Finally, put all the basic dimensions and reference dimensions at the end
  sortedShapes = sortBy(sortedShapes, shape => {
    if (shape.isBasic) return 1
    if (shape.isReference) return 2
    return 0
  })

  return sortedShapes
}

const getShapeLocation = (
  dim: Dim,
  highqaDrawingIdToPageIndex: Record<number, number>
): ShapeLocation => {
  const pageIndex = highqaDrawingIdToPageIndex[dim.DimDrawingID]
  const topLeft = dim.ShapeCenter.split(",")
  const extents = dim.ShapePoints.split(",")
  return {
    pageIndex,
    topLeft: { x: +topLeft[0], y: +topLeft[1] },
    extents: { x: +extents[0], y: +extents[1] },
  }
}

const getDimBbox = (dim: Dim): DimBBox => {
  const top = dim.ShapeCenter.split(",")
  const extent = dim.ShapePoints.split(",")

  return {
    bbox: {
      topLeft: {
        x: +top[0],
        y: +top[1],
      },
      extents: {
        x: +extent[0],
        y: +extent[1],
      },
    },
    dim,
  }
}
