import { Color } from "pdf-lib"

interface Point {
  x: number
  y: number
}

export interface BBox {
  topLeft: Point
  extents: Point
}

export interface DimBBox {
  bbox: BBox
  dim: Dim
}

export interface ShapeData {
  shapeId: number // only for transient use within remoteshop
  label: string
  location: ShapeLocation
  dims: Dim[]
  scoring: DimsScoring
  isBasic: boolean
  isReference: boolean
  hasDatum: boolean
}

export interface Dim {
  ShapeCenter: string
  ShapePoints: string
  ShapeTitle: string
  DimRequirement: string
  DimRequirement2: string
  DimDrawingID: number
  DimUnits: number
  DimData: string | number
  DimCode: string
  DimSort: string
  DimDatMod1: string | undefined
  DimDatMod2: string | undefined
  DimDatMod3: string | undefined
  DimDatum1: string | undefined
  DimDatum2: string | undefined
  DimDatum3: string | undefined
  DimTolMod2: string
  DimTolType: number
  DimText: string
  DimType: number
  // TODO: Want to understand DimTolCalc
  DimTolCalc: number
  DimUpperTol: number
  DimLowerTol: number
}

export interface Drawing {
  ID: number
  DrawingPageNo: number
}

export interface ShapesFilterOptions {
  pageIndex?: number
  includeBasicDims?: boolean
  includeReferenceDims?: boolean
}

export interface DimsDataOptions {
  drawingId?: string
  jobId?: string
  filters?: ShapesFilterOptions
}

export enum DimTolType {
  unspecified = 0,
  tolerance = 1,
  asLimit = 2,
  basic = 3,
  reference = 4,
  min = 5,
  max = 6,
}

export enum DimType {
  angular = 1,
  angularity = 2,
  circularity = 6,
  concentricity = 8,
  cylindricity = 11,
  diameter = 12,
  flatness = 13,
  linear = 15,
  width = 16,
  note = 19,
  parallelism = 20,
  perpendicularity = 21,
  radial = 25,
  surfaceProfile = 27,
  totalRunout = 29,
  truePosition = 30,
  depth = 33,
  chamfer = 34,
  thread = 35,
  surfaceFinish = 36,
  counterbore = 38,
  countersink = 39,
  spotface = 51,
}

export enum DimUnits {
  metric = 1,
  imperial = 2,
  degrees = 3,
  radians = 4,
}

export type ShapeLocation = { pageIndex: number } & BBox

export interface DimsData {
  drawingId?: string // UUID
  drawingFileId?: string // UUID
  drawingLocator?: string
  jobLabel?: string
  allShapes: ShapeData[]
  filteredShapes: ShapeData[]
  loading: boolean
  refetch?: () => Promise<unknown>
}

export interface DimsScoring {
  difficultyGroup: DifficultyGroup
  safetyFactor: number
  toleranceBandSize: number
  hardestDimDescription: string
}

export enum DifficultyGroup {
  yellow = "yellow",
  blue = "blue",
  gray = "gray",
}

export interface BubbleAppearance {
  fill: { color: Color; bubbleOpacity: number; boxOpacity: number; hideBox?: boolean }
  textColor: Color
}

export interface Bubble {
  label: string
  location: ShapeLocation
  appearance: BubbleAppearance
  scoring: DimsScoring
}
