import { Dim, DimTolType, DimUnits } from "src/pages/DrawingViewer/interfaces"

export const getDatums = (dims: Dim[]): string[] => {
  return Array.from(
    new Set(
      dims
        .map(dim => {
          const datums: string[] = []

          if (dim.DimDatum1 !== undefined && isNaN(+dim.DimDatum1)) {
            datums.push(dim.DimDatum1)
          }

          if (dim.DimDatum2 !== undefined && isNaN(+dim.DimDatum2)) {
            datums.push(dim.DimDatum2)
          }

          if (dim.DimDatum3 !== undefined && isNaN(+dim.DimDatum3)) {
            datums.push(dim.DimDatum3)
          }

          return datums
        })
        .flat()
    )
  )
}

export const hasDatum = (dims: Dim[]): boolean => {
  return getDatums(dims).length > 0
}

export const getToleranceBandSize = (dims: Dim[]): number => {
  return Math.min(
    ...dims.map(dim => {
      let bandSize = dim.DimUpperTol - dim.DimLowerTol
      if (dim.DimUnits === DimUnits.imperial) {
        bandSize *= 25.4
      }
      return bandSize
    })
  )
}

export const isBasic = (dims: Dim[]): boolean => {
  return dims.every(dim => dim.DimTolType === DimTolType.basic)
}

export const isReference = (dims: Dim[]): boolean => {
  return dims.every(dim => dim.DimTolType === DimTolType.reference)
}

export const dimLabel = (dims: Dim[]): string => {
  if (dims.length === 1) {
    return dims[0].DimCode
  }

  const labelSet = new Set()

  dims.forEach(val => {
    labelSet.add(val.DimCode.split(".")[0])
  })

  return Array.from(labelSet.values()).join(",")
}
