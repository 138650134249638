import { PlansToBackup, SimulationMode } from "src/client-axios"
import { sendEvent } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useLocalStorageSettings } from "src/hooks/useLocalStorageSettings"
import { useToaster } from "src/hooks/useToaster"

type generateArchiveFn = () => void

export const useOperationArchiveDownloader = ({
  planId,
  operationIdx,
  ncProgramLocator,
  vericut,
}: {
  planId: string
  operationIdx?: number
  ncProgramLocator?: string
  vericut?: boolean
}): generateArchiveFn => {
  const { tasksApi, submissionApi } = useApi()
  const toaster = useToaster()

  const {
    settings: { exportCoordinates: coordinates },
  } = useLocalStorageSettings()

  return () => {
    if (vericut && operationIdx !== undefined && ncProgramLocator !== undefined) {
      submissionApi
        .submitCamOperation(planId, operationIdx, ncProgramLocator, SimulationMode.Archive)
        .then(async () => {
          sendEvent("downloadVericut", { planId, operationIdx, ncProgramLocator })
        })
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Failed to download vericut archive: ${err}` })
        })
    } else {
      const archivePromise =
        operationIdx !== undefined
          ? tasksApi.generateOperationArchive(planId, operationIdx, coordinates)
          : tasksApi.generatePlanArchive(planId, coordinates)
      archivePromise
        .then(async () => {
          sendEvent("generateArchive", { planId, operationIdx })
        })
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Failed to download archive: ${err}` })
        })
    }
  }
}

export const useFixtureArchiveDownloader = (identifier?: string): generateArchiveFn => {
  const { tasksApi } = useApi()
  const toaster = useToaster()

  return () => {
    if (identifier) {
      toaster.show({ message: `Generating fixture stack...` })
      tasksApi
        .generateFixtureStackArchive(identifier)
        .then(async () => {
          sendEvent("generateFixtureArchive", { identifier }) // Event logging
        })
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Failed to generate fixture stack archive: ${err}` })
        })
    }
  }
}

type generateJobBackupFn = (
  jobId?: string,
  planId?: string,
  includeJobFiles?: boolean,
  includeManifests?: boolean,
  includeVericutReportFiles?: boolean,
  jobPlans?: PlansToBackup
) => void

export const useJobBackupDownloader = (): generateJobBackupFn => {
  const { tasksApi } = useApi()
  const toaster = useToaster()

  return (
    jobId?: string,
    planId?: string,
    includeJobFiles?: boolean,
    includeManifests?: boolean,
    includeVericutReportFiles?: boolean,
    jobPlans?: PlansToBackup
  ) => {
    if (jobId !== undefined) {
      tasksApi
        .createJobBackupAsync(
          jobId,
          includeJobFiles,
          includeManifests,
          includeVericutReportFiles,
          jobPlans
        )
        .then(async result => {
          // React magic to cause the progress toast to "stick around"
          toaster.show({ message: `Download job backup: ${jobId}` }, result.data.taskId)
          sendEvent("exportJob", { jobId }) // Event logging
        })
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Failed to download job backup: ${err}` })
        })
    } else if (planId !== undefined) {
      tasksApi
        .createPlanBackupAsync(planId)
        .then(async result => {
          // React magic to cause the progress toast to "stick around"
          toaster.show({ message: `Download plan backup: ${planId}` }, result.data.taskId)
          sendEvent("exportPlan", { planId }) // Event logging
        })
        .catch(err => {
          console.error(err)
          toaster.show({ message: `Failed to download plan backup: ${err}` })
        })
    }
  }
}
