import { getDimDescription } from "src/pages/DrawingViewer/description"
import {
  DifficultyGroup,
  Dim,
  DimsScoring,
  DimType,
  DimUnits,
} from "src/pages/DrawingViewer/interfaces"
import { getDatums, getToleranceBandSize, isBasic, isReference } from "./util/dim"

export const getDimsScoring = (dims: Dim[]): DimsScoring => {
  const knownDims = dims.filter(dim => dim.DimType in DimType && !isNaN(+dim.DimData))
  if (knownDims.length === 0)
    return {
      difficultyGroup: DifficultyGroup.gray,
      safetyFactor: Infinity,
      toleranceBandSize: Infinity,
      hardestDimDescription: "",
    }

  const toleranceBandSize = +getToleranceBandSize(knownDims).toFixed(4)
  const { safetyFactor, dimDescription: hardestDimDescription } = getWorstSafetyFactor(knownDims)

  let difficultyGroup: DifficultyGroup
  if (safetyFactor < 1.5) {
    difficultyGroup = DifficultyGroup.yellow
  } else if (safetyFactor < 3.999) {
    difficultyGroup = DifficultyGroup.blue
  } else {
    difficultyGroup = DifficultyGroup.gray
  }

  return { difficultyGroup, safetyFactor, toleranceBandSize, hardestDimDescription }
}

enum DifficultyComputationGroup {
  contour,
  machiningMethod,
  multiPlaneAxial,
  multiPlaneRadial,
  profile,
  surfaceFinish,
  none,
}

const DIFFICULTY_COMPUTATION_MAPPING: Record<number, DifficultyComputationGroup | undefined> = {
  [DimType.angular]: DifficultyComputationGroup.none,
  [DimType.angularity]: DifficultyComputationGroup.multiPlaneAxial,
  [DimType.chamfer]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.circularity]: DifficultyComputationGroup.machiningMethod,
  [DimType.concentricity]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.counterbore]: DifficultyComputationGroup.contour,
  [DimType.countersink]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.cylindricity]: DifficultyComputationGroup.machiningMethod,
  [DimType.depth]: DifficultyComputationGroup.multiPlaneAxial,
  [DimType.diameter]: DifficultyComputationGroup.contour,
  [DimType.flatness]: DifficultyComputationGroup.machiningMethod,
  [DimType.linear]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.note]: DifficultyComputationGroup.none,
  [DimType.parallelism]: DifficultyComputationGroup.multiPlaneAxial,
  [DimType.perpendicularity]: DifficultyComputationGroup.multiPlaneAxial,
  [DimType.radial]: DifficultyComputationGroup.contour,
  [DimType.surfaceProfile]: DifficultyComputationGroup.profile,
  [DimType.thread]: DifficultyComputationGroup.none,
  [DimType.totalRunout]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.truePosition]: DifficultyComputationGroup.multiPlaneRadial,
  [DimType.spotface]: DifficultyComputationGroup.multiPlaneAxial,
  [DimType.surfaceFinish]: DifficultyComputationGroup.surfaceFinish,
  [DimType.width]: DifficultyComputationGroup.contour,
}

enum CapabilityKind {
  contour,
  inPlane,
  multiPlaneRadial,
  multiPlaneAxial,
  profile,
}

const NO_DIFFICULTY_NUMERATOR = 1000.0
// callout can be used to provide additional dims as context beyond the ones you want to use for the safetyFactor
const getWorstSafetyFactor = (
  dims: Dim[],
  callout?: Dim[]
): { safetyFactor: number; dimDescription: string } => {
  if (dims.length === 0) return { safetyFactor: Infinity, dimDescription: "" }

  const safetyFactors = dims.map(dim => getSafetyFactorDetails(dim, callout ?? dims).safetyFactor)
  let worstSafetyFactorIndex = 0
  let worstSafetyFactor = Infinity
  safetyFactors.forEach((safetyFactor, index) => {
    if (safetyFactor < worstSafetyFactor) {
      worstSafetyFactor = safetyFactor
      worstSafetyFactorIndex = index
    }
  })
  const dimDescription = getDimDescription(dims[worstSafetyFactorIndex])
  return { safetyFactor: worstSafetyFactor, dimDescription }
}

const getSafetyFactorDetails = (
  dim: Dim,
  _coincidentDims: Dim[]
): { safetyFactor: number; explanation: string } => {
  const numerator = getSafetyFactorNumerator(dim)
  const denominator = getSafetyFactorDenominator(dim)
  const safetyFactor = numerator / denominator
  // TODO: Implement a better description
  return { safetyFactor, explanation: "<Placeholder for difficulty explanation>" }
}

const getSafetyFactorNumerator = (dim: Dim): number => {
  if (isBasic([dim])) return 2 * NO_DIFFICULTY_NUMERATOR
  if (isReference([dim])) return 3 * NO_DIFFICULTY_NUMERATOR

  const group = DIFFICULTY_COMPUTATION_MAPPING[dim.DimType]
  switch (group) {
    case DifficultyComputationGroup.none:
    case undefined:
      return NO_DIFFICULTY_NUMERATOR

    case DifficultyComputationGroup.surfaceFinish:
      return getSurfaceFinishMicrons(dim)

    case DifficultyComputationGroup.contour:
    case DifficultyComputationGroup.machiningMethod:
    case DifficultyComputationGroup.multiPlaneAxial:
    case DifficultyComputationGroup.multiPlaneRadial:
    case DifficultyComputationGroup.profile: {
      const baseBandSize = getToleranceBandSize([dim])
      if (baseBandSize === 0) {
        // This means the tolerance band is probably not set correctly
        return NO_DIFFICULTY_NUMERATOR
      }
      if (dim.DimType === DimType.radial) return Math.min(2 * baseBandSize, NO_DIFFICULTY_NUMERATOR)
      return Math.min(baseBandSize, NO_DIFFICULTY_NUMERATOR)
    }
  }
}

const NO_DIFFICULTY_DENOMINATOR = 0.001

const getSafetyFactorDenominator = (dim: Dim): number => {
  if (isBasic([dim])) return NO_DIFFICULTY_DENOMINATOR / 2
  if (isReference([dim])) return NO_DIFFICULTY_DENOMINATOR / 3

  const group = DIFFICULTY_COMPUTATION_MAPPING[dim.DimType]
  switch (group) {
    case DifficultyComputationGroup.none:
    case undefined:
      return NO_DIFFICULTY_DENOMINATOR

    case DifficultyComputationGroup.surfaceFinish:
      return 0.6 // Set to make 0.4 show up as red

    case DifficultyComputationGroup.contour:
    case DifficultyComputationGroup.machiningMethod:
      return getSafetyFactorDenominatorForCapability(CapabilityKind.contour)

    case DifficultyComputationGroup.multiPlaneAxial:
      return getSafetyFactorDenominatorForCapability(CapabilityKind.multiPlaneAxial)

    case DifficultyComputationGroup.multiPlaneRadial: {
      if (getEstimatedMachiningPlanesCount([dim]) <= 1) {
        return getSafetyFactorDenominatorForCapability(CapabilityKind.inPlane)
      } else {
        return getSafetyFactorDenominatorForCapability(CapabilityKind.multiPlaneRadial)
      }
    }

    case DifficultyComputationGroup.profile:
      return getSafetyFactorDenominatorForCapability(CapabilityKind.profile)
  }
}

const getSafetyFactorDenominatorForCapability = (capabilityKind: CapabilityKind): number => {
  switch (capabilityKind) {
    case CapabilityKind.contour:
      return 0.01
    case CapabilityKind.inPlane:
      return 0.012
    case CapabilityKind.multiPlaneRadial:
      return 0.018
    case CapabilityKind.multiPlaneAxial:
      return 0.019
    case CapabilityKind.profile:
      return 0.02
  }
}

const getEstimatedMachiningPlanesCount = (dims: Dim[]): number => {
  // Count multi-letter datums as one plane per datum
  const datums = getDatums(dims)
  let count = 0
  for (const datum of datums) {
    count += datum.replace("-", "").length
  }
  return count
}

const getSurfaceFinishMicrons = (dim: Dim): number => {
  const NO_CANDIDATES_VALUE = 0.1
  let candidates = [
    +(dim.DimDatMod1 ?? 0),
    +(dim.DimDatMod2 ?? 0),
    +(dim.DimDatMod3 ?? 0),
    dim.DimUpperTol,
  ]
  if (dim.DimUnits === DimUnits.imperial) {
    // convert from microinches to microns
    candidates = candidates.map(x => (x * 25.4) / 1000)
  }
  candidates = candidates.filter(x => !isNaN(x) && x > 0)
  return candidates[0] ?? NO_CANDIDATES_VALUE
}

// Notes:
// Radial: depends on whether this is a tool corner radius or a partial circle
//     Maybe try to infer based on size?
// Angular: can convert to angularity by using size of feature, maybe in the future
//    (Generally, if critical, we expect it would be converted to angularity)
// Surface finish: talk with Zac about what the difficulty should be
//    Zac says:
//      Easy: 125µin/3.2µm
//      Medium: 63µin/1.6µm
//      Hard: 16µin/0.4µm
//      Impossible: 8µin/0.2µm
// MULTI_PLANE_AXIAL group: May want to adjust difficulty based on the size of the feature
//     In this case, we could possibly use the size of part as a reference
