import React, { FC, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Button, Classes, Dialog, Icon, Spinner } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"

import { useNetworkErrorToast, useToaster } from "src/hooks/useToaster"

import styles from "./FileUpload.module.css"

export enum FileUploadMode {
  NcProgramUpload = "NcProgramUpload",
  CamProjectUpload = "CamProjectUpload",
  VericutProjectUpload = "VericutProjectUpload",
}

export const FileUpload: FC<{
  tooltip: string
  label: string
  onUpload: (file: File) => Promise<unknown>
  fileUploadMode: FileUploadMode
  extensionWhitelist?: string[]
  extensionBlacklist?: string[]
  allowOverride?: boolean
  locked?: boolean
}> = ({
  tooltip,
  label,
  onUpload,
  fileUploadMode,
  extensionWhitelist,
  extensionBlacklist,
  allowOverride,
  locked,
}) => {
  const toaster = useToaster()
  const errorToast = useNetworkErrorToast()

  const [uploadingCount, setUploadingCount] = useState(0)
  const [toUpload, setToUpload] = useState<File>()

  const onFileUpload = useCallback(
    (file: File) => {
      setUploadingCount(prev => prev + 1)
      onUpload(file).then(
        () => {
          toaster.show({ message: `Uploaded ${label}`, intent: "success" })
          setUploadingCount(prev => prev - 1)
        },
        (error: AxiosError) => {
          const message = `Failed to upload ${label}`
          errorToast(error, message)
          setUploadingCount(prev => prev - 1)
        }
      )
    },
    [label, onUpload, toaster, errorToast]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        if (locked) {
          return
        }
        const hasValidExtension =
          (extensionWhitelist?.some(extension =>
            file.name.toLocaleLowerCase().endsWith(extension)
          ) ??
            true) &&
          !extensionBlacklist?.some(extension => file.name.toLocaleLowerCase().endsWith(extension))
        if (!hasValidExtension) {
          setToUpload(file)
        } else {
          onFileUpload(file)
        }
      })
    },
    [extensionWhitelist, extensionBlacklist, onFileUpload, locked]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const tooltipClasses = `${
    fileUploadMode === FileUploadMode.CamProjectUpload ? styles.camTooltipContainer : ""
  }`

  return (
    <>
      <Tooltip2 placement={"top"} content={tooltip} openOnTargetFocus={false}>
        <div
          className={`${tooltipClasses} ${Classes.BUTTON} ${locked ? Classes.DISABLED : ""} ${
            isDragActive ? Classes.INTENT_SUCCESS : ""
          }`}
          {...(locked ? {} : getRootProps())}
        >
          {uploadingCount > 0 ? (
            <div>
              <Spinner size={16} />
            </div>
          ) : (
            <>
              <input {...getInputProps()} />
              <Icon icon={"upload"} />
              <div>Upload {label}</div>
            </>
          )}
        </div>
      </Tooltip2>
      {toUpload && (
        <Dialog
          icon={"error"}
          onClose={() => {
            setToUpload(undefined)
          }}
          title={`Upload ${label}`}
          isOpen={toUpload !== undefined}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            {extensionWhitelist && (
              <p>
                <code className={Classes.CODE}>{toUpload.name}</code> does not have an expected{" "}
                {label} extension:{" "}
                <ul>
                  {extensionWhitelist.map(x => (
                    <li key={x}>
                      <code className={Classes.CODE}>*{x}</code>
                    </li>
                  ))}
                </ul>
              </p>
            )}
            {!extensionWhitelist && extensionBlacklist && (
              <p>
                <code className={Classes.CODE}>{toUpload.name}</code> has an unexpected {label}{" "}
                extension
              </p>
            )}
            {allowOverride ? (
              <div>
                Are you sure you want to upload{" "}
                <code className={Classes.CODE}>{toUpload.name}</code>?
              </div>
            ) : (
              <div>Please provide a file with a different extension.</div>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setToUpload(undefined)}>Cancel</Button>
              {allowOverride && (
                <Button
                  onClick={() => {
                    onFileUpload(toUpload)
                    setToUpload(undefined)
                  }}
                  intent="danger"
                >
                  Upload
                </Button>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
