import React, { FC, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Classes, Dialog, HTMLTable, Spinner } from "@blueprintjs/core"
import { format } from "date-fns"

import { sendEvent } from "src/components/Websocket/Websocket"
import { usePlanHistoryQuery, useRevertToRevisionMutation } from "src/graphql/generated"

import styles from "./PlanHistory.module.css"

export const PlanHistory: FC = () => {
  const { planId } = useParams<{
    planId: string
  }>()

  sendEvent("viewPlanHistory", { planId })

  const { data, loading } = usePlanHistoryQuery({ variables: { planId } })

  const [curRevision, setRevision] = useState<number | undefined>()
  const [isOpen, setIsOpen] = useState(false)

  let planPatches = data?.planPatchRecords?.nodes

  if (loading || planPatches === undefined) {
    return <Spinner />
  }

  planPatches = [...planPatches]
  planPatches.reverse()

  return (
    <>
      <HTMLTable
        className={styles.planHistory}
        bordered={true}
        condensed={true}
        interactive={true}
        striped={true}
      >
        <thead>
          <tr>
            <th>Rev #</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Op</th>
            <th>Path</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {planPatches.map((val, i) => {
            return (
              <tr key={i}>
                <td>
                  {val.revision}{" "}
                  <Button
                    icon={"undo"}
                    minimal
                    onClick={() => {
                      setRevision(val.revision)
                      setIsOpen(true)
                    }}
                  />
                </td>
                <td>{val.createdBy}</td>
                <td>{format(new Date(val.createdAt), "MMM d h:mma")}</td>
                <td>{val.op}</td>
                <td>
                  {val.from ? (
                    <>
                      <pre className={Classes.CODE_BLOCK}>{val.from}</pre> To{" "}
                    </>
                  ) : (
                    ""
                  )}
                  <pre className={Classes.CODE_BLOCK}>{val.path}</pre>
                </td>
                <td>
                  <pre className={Classes.CODE_BLOCK}>
                    <RenderValue value={val.value} path={val.path} planId={planId} />
                  </pre>
                </td>
              </tr>
            )
          })}
        </tbody>
      </HTMLTable>
      {curRevision && (
        <RevertOperationDialog
          planId={planId}
          isOpen={isOpen}
          handleRequestClose={() => {
            setIsOpen(false)
          }}
          revision={curRevision}
        />
      )}
    </>
  )
}

const opMatch = /operations\/(\d+)/

const RenderValue: FC<{
  value?: Record<string, unknown> | string | null
  path: string
  planId: string
}> = ({ value, path, planId }) => {
  if (path.endsWith("modelId") && typeof value === "string") {
    return <Link to={`/3d-viewer?modelId=${value}`}>{value}</Link>
  }

  if (path.endsWith("ncFileId") && typeof value === "string") {
    const match = path.match(opMatch)

    if (match) {
      return <Link to={`/nc-edit/${planId}/${match[1]}/${value}`}>{value}</Link>
    }
  }

  return <>{JSON.stringify(value, null, 2)}</>
}

const RevertOperationDialog: FC<{
  planId: string
  isOpen: boolean
  revision: number
  handleRequestClose: () => void
}> = ({ planId, isOpen, handleRequestClose, revision }) => {
  const [revertToRevision] = useRevertToRevisionMutation()

  const { refetch, data } = usePlanHistoryQuery({ variables: { planId } })

  const patch = data?.planPatchRecords?.nodes.find(val => val.revision === revision)

  const revert = () => {
    revertToRevision({
      variables: {
        planId,
        revision,
      },
    }).then(() => {
      refetch().then(() => {
        handleRequestClose()
      })
    })
  }

  if (patch === undefined) {
    return <></>
  }

  return (
    <Dialog
      icon={"undo"}
      onClose={handleRequestClose}
      title={"Revert to a Previous Revision"}
      isOpen={isOpen}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>Are you sure you want to revert to revision #{patch.revision}?</p>
        <p>
          <strong>Created By:</strong> {patch.createdBy}
        </p>
        <p>
          <strong>Created At:</strong> {format(new Date(patch.createdAt), "MMM d h:mma")}
        </p>
        <p>
          <strong>Operation:</strong> {patch.op}
        </p>
        <strong>Path:</strong> <pre className={Classes.CODE_BLOCK}>{patch.path}</pre>
        <br />
        <strong>Value: </strong>
        <pre className={Classes.CODE_BLOCK}>
          <RenderValue value={patch.value} path={patch.path} planId={planId} />
        </pre>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleRequestClose}>Close</Button>
          <Button onClick={revert} intent="warning">
            Revert
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
