import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { AnchorButton, Button, Callout, Menu, MenuItem } from "@blueprintjs/core"
import copy from "copy-to-clipboard"
import { Maybe } from "graphql/jsutils/Maybe"

import { Operation, Plan } from "src/client-axios"
import { Assignee } from "src/components/Cam/Assignee/Assignee"
import { DeletePlanModal } from "src/components/Cam/DeletePlanModal/DeletePlanModal"
import { PlanLabel } from "src/components/Cam/Labels/Labels"
import { PasteMenuItem } from "src/components/Generic/Clipboard/PasteMenuItem/PasteMenuItem"
import { ContextTarget } from "src/components/Generic/ContextTarget/ContextTarget"
import {
  CamJobDetailsFragment,
  useUpdateCamJobActivePlanMutation,
  useUpdatePlanMutation,
} from "src/graphql/generated"
import { useApi } from "src/hooks/useApi"
import {
  useJobBackupDownloader,
  useOperationArchiveDownloader,
} from "src/hooks/useArchiveDownloader"
import { useToaster } from "src/hooks/useToaster"
import { formatSecondsDuration } from "src/pages/GCodeEditor/ToolChangeTable/ToolChangeTable"
import { UndoButtons } from "src/pages/ViewerPage/Cards/ControlsHelper/ControlsHelper"
import { activeSelectors } from "src/store/cam/active"
import { storedPlansSelectors } from "src/store/cam/storedPlans"
import { RootState } from "src/store/rootStore"
import { viewerModalSelectors } from "src/store/ui/viewerModal"
import { NoOperationsCard, OperationCard } from "../OperationCard/OperationCard"
import { StockCard } from "../StockCard/StockCard"
import { CreateOperationButton } from "./CreateOperationButton/CreateOperationButton"
import { NotesEditor } from "./NotesEditor/NotesEditor"

import styles from "./OverviewTabPanel.module.css"

export const OverviewTabPanel: FC<{
  planId: string
  camJob: CamJobDetailsFragment
  minimal?: boolean
  locked?: boolean
  revision?: number
  assignees?: Maybe<string[]> | undefined
  onJobDetailsChange: () => void
  jobUri?: string
}> = ({ planId, camJob, onJobDetailsChange, minimal, locked, assignees, revision, jobUri }) => {
  const { plansApi, planchangerApi } = useApi()
  const toaster = useToaster()
  const [updateCamJobActivePlanMutation] = useUpdateCamJobActivePlanMutation()

  const operations =
    useSelector((state: RootState) => storedPlansSelectors.selectOperations(state, planId)) ?? []

  const viewerModalIsOpen = useSelector(viewerModalSelectors.selectIsOpen)

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const plan = useSelector((state: RootState) => storedPlansSelectors.selectPlan(state, planId))
  const generateArchive = useOperationArchiveDownloader({ planId })
  const generateJobBackup = useJobBackupDownloader()

  const handleSetActivePlan = () => {
    updateCamJobActivePlanMutation({
      variables: {
        id: camJob.id,
        activePlanId: planId,
      },
    })
    onJobDetailsChange()
  }

  const labelText =
    useSelector((state: RootState) => storedPlansSelectors.selectPlanLabel(state, planId)) ?? ""

  const handleDuplicateClick = () => {
    plansApi.duplicatePlan(planId, `${labelText} (Copy)`).then(() => {
      onJobDetailsChange()
    })
  }

  const handleBackupPlan = () => {
    generateJobBackup(undefined, planId, undefined, undefined)
  }

  const pastePlanJson = (pastedText: string) => {
    const plan = parseClipboardPlan(pastedText)
    if (!plan) {
      toaster.show({
        message: "The clipboard contents did not reference a RemoteShop plan",
        icon: "error",
        intent: "warning",
      })
      return
    }

    planchangerApi
      .postPlanchangerPlan(planId, plan)
      .then(() => toaster.show({ message: "Pasted plan", icon: "clipboard", intent: "success" }))
      .catch(() =>
        toaster.show({ message: "Failed to paste plan", icon: "clipboard", intent: "danger" })
      )
  }

  return (
    <div className={styles.container}>
      <div className={styles.planDetailsRow}>
        <ContextTarget
          menu={
            <Menu>
              <MenuItem
                text={"Copy plan UUID"}
                onClick={() => {
                  toaster.show({ message: "Copied plan UUID" })
                  copy(planId)
                }}
              />
              <MenuItem
                text={"Copy plan JSON"}
                onClick={() => {
                  toaster.show({ message: "Copied plan JSON" })
                  copy(JSON.stringify(plan, null, 2))
                }}
              />
              <PasteMenuItem text={"Paste plan JSON"} onPaste={pastePlanJson} />
              <MenuItem text={"Export This Plan"} onClick={handleBackupPlan} />
              <MenuItem text={"View History"} href={`/plan-history/${planId}`} />
            </Menu>
          }
        >
          <h4 className={"bp3-heading"} style={{ textAlign: "left" }}>
            Selected Plan Details:
          </h4>
        </ContextTarget>
        {!minimal && (
          <div className={styles.buttonsContainer}>
            <UndoButtons className={styles.activePlanButton} locked={locked} />

            {camJob.activePlanId !== planId && (
              <Button
                intent="primary"
                onClick={handleSetActivePlan}
                className={styles.activePlanButton}
                disabled={locked}
              >
                Set Active Plan
              </Button>
            )}

            <Button onClick={generateArchive} className={styles.duplicatePlanButton}>
              Download All CAM Files
            </Button>

            <Button
              onClick={handleDuplicateClick}
              className={styles.duplicatePlanButton}
              disabled={locked}
            >
              Duplicate
            </Button>

            <Button intent="danger" onClick={() => setModalIsOpen(true)} disabled={locked}>
              Delete
            </Button>
          </div>
        )}
      </div>
      {plan && camJob.jobRevision !== plan.jobRevision && (
        <PlanJobRevisionControl
          jobRevision={camJob.jobRevision}
          planJobRevision={plan.jobRevision}
          planId={planId}
        />
      )}
      <div className={styles.assigneeContainer}>
        <div className={styles.assigneeLabel}>Assignee: </div>
        <Assignee planId={planId} camProgrammers={assignees} disabled={locked} />
      </div>
      <div className={styles.planLabelContainer}>
        <PlanLabel planId={planId} leader editable />
      </div>
      <TotalRuntime />

      <NotesEditor planId={planId} smallButton locked={locked} />
      <div className={styles.cardsContainer}>
        <div className={styles.groupContainer}>
          {operations.length > 0 ? (
            <StockCard
              minimal={minimal}
              camJob={camJob}
              planId={planId}
              operationIdx={0}
              pauseRender={viewerModalIsOpen}
              locked={locked}
            />
          ) : (
            <NoOperationsCard
              jobId={camJob?.id}
              planId={planId}
              partModel={camJob?.model ?? undefined}
              pauseRender={viewerModalIsOpen}
            />
          )}

          {minimal !== true && !locked && (
            <CreateOperationButton planId={planId} operationIdx={0} />
          )}
        </div>

        {/* Completely remove the op cards from the virtual DOM while showing the viewer modal */}
        {/* This improves the performance in the viewer model, but means it is a bit slower to render the cards */}
        {/* when you close the modal */}
        {!viewerModalIsOpen &&
          operations.map((operation, i) => {
            return (
              <div key={operation.id} className={styles.parentContainer}>
                <div className={styles.groupContainer}>
                  {operation.inputStock !== undefined && i !== 0 && (
                    <StockCard
                      minimal={minimal}
                      camJob={camJob}
                      planId={planId}
                      operationIdx={i}
                      pauseRender={viewerModalIsOpen}
                    />
                  )}
                  <OperationCard
                    showEdit={minimal !== true}
                    showNcProgram={minimal !== true}
                    showActions={minimal !== true}
                    planId={planId}
                    operationIdx={i}
                    revision={revision}
                    jobId={camJob?.id}
                    partModel={camJob?.model ?? undefined}
                    editableLabel={!minimal}
                    pauseRender={viewerModalIsOpen}
                    locked={locked}
                    jobUri={jobUri}
                    assignees={assignees}
                  />
                  {minimal !== true && !locked && (
                    <CreateOperationButton planId={planId} operationIdx={i + 1} />
                  )}
                </div>
              </div>
            )
          })}
      </div>

      <DeletePlanModal
        camJob={camJob}
        planId={planId}
        isOpen={modalIsOpen}
        close={() => setModalIsOpen(false)}
        onDelete={onJobDetailsChange}
      />
    </div>
  )
}

const TotalRuntime: FC = () => {
  const toaster = useToaster()

  const planId = useSelector(activeSelectors.selectActivePlanId)
  const plan = useSelector((state: RootState) => storedPlansSelectors.selectPlan(state, planId))
  const opRuntimes = useSelector(activeSelectors.selectActiveOpRuntimes)
  let totalRuntime = 0
  let hasTotalRuntime = plan !== undefined
  plan?.operations.map(op => {
    if (opRuntimes && opRuntimes[op.id] !== undefined) {
      totalRuntime += opRuntimes[op.id]
    } else {
      hasTotalRuntime = false
    }
  })

  const timeText =
    !hasTotalRuntime || totalRuntime === 0 ? "---" : formatSecondsDuration(totalRuntime)
  const copyTimeText = () => {
    copy(timeText ?? "")
    toaster.show({ message: `Copied simulated runtime`, icon: "clipboard", intent: "primary" })
  }

  return (
    <div>
      <b>Total Runtime:</b>
      <AnchorButton minimal intent={"none"} onClick={copyTimeText}>
        {timeText}
      </AnchorButton>
    </div>
  )
}

const PlanJobRevisionControl: FC<{
  jobRevision: number
  planJobRevision: number
  planId: string
}> = ({ jobRevision, planJobRevision, planId }) => {
  const toaster = useToaster()
  const [updatePlan] = useUpdatePlanMutation({
    onCompleted: () => {
      toaster.show({
        message: "Job revision update acknowledged",
        intent: "success",
        icon: "outdated",
      })
    },
  })

  const updatePlanJobRevision = () => {
    updatePlan({
      variables: { planId, jobRevision },
    })
  }
  return (
    <Callout className={styles.planJobRevisionBanner} intent={"warning"}>
      <p>
        This plan was developed against job revision {planJobRevision}, but this job is currently on
        revision {jobRevision}.
      </p>
      <p>
        <b>
          Before continuing work on this plan, please confirm acknowledgement of the changes to this
          job.
        </b>
      </p>
      <Button intent={"warning"} onClick={() => updatePlanJobRevision()}>
        Acknowledge Job Revision {jobRevision}
      </Button>
    </Callout>
  )
}

const parseClipboardPlan = (text: string): Plan | undefined => {
  try {
    const parsed = JSON.parse(text) as Record<string, unknown>
    const { label, jobRevision, operations, notes } = parsed

    if (typeof label !== "string") return undefined
    if (typeof jobRevision !== "number") return undefined
    if (!Array.isArray(operations)) return undefined
    if (notes !== undefined && typeof notes !== "string") return undefined
    return { label, jobRevision, operations: operations as Operation[], notes }
  } catch (err) {
    return undefined
  }
}
