import React, { FC, useMemo } from "react"
import { useSelector } from "react-redux"
import { Button, ButtonGroup, Menu, MenuItem, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { AxiosError } from "axios"

import { sendEvent } from "src/components/Websocket/Websocket"
import { useApi } from "src/hooks/useApi"
import { useNetworkErrorToast } from "src/hooks/useToaster"
import { storedOperationSelectors } from "src/store/cam/storedPlans"
import { FileUpload, FileUploadMode } from "./FileUpload/FileUpload"

export const NcProgramUpload: FC<{
  planId: string
  operationIdx: number
  locked?: boolean
}> = ({ planId, operationIdx, locked }) => {
  const { planchangerApi } = useApi()

  return (
    <ButtonGroup>
      <FileUpload
        fileUploadMode={FileUploadMode.NcProgramUpload}
        tooltip={"Drag and drop the main NC program here. You can also click to select."}
        label={"NC file"}
        onUpload={file => planchangerApi.ncProgram(planId, operationIdx, file)}
        extensionWhitelist={[".nc", ".mpf", "*.txt"]}
        locked={locked}
      />
      <Popover2
        position={Position.BOTTOM}
        disabled={locked}
        content={
          <Menu>
            <CamProjectUpload planId={planId} operationIdx={operationIdx} />
          </Menu>
        }
      >
        <Button icon={"chevron-down"} disabled={locked} />
      </Popover2>
    </ButtonGroup>
  )
}

export const CamProjectUpload: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const { filesApi } = useApi()
  const { plansApi } = useApi()

  const errorToast = useNetworkErrorToast()

  const selectToolpathProject = useMemo(
    () => storedOperationSelectors.createSelectToolpathProject(planId, operationIdx),
    [planId, operationIdx]
  )

  const toolpathProject = useSelector(selectToolpathProject)

  const unlinkCamProject = () => {
    plansApi
      .unlinkCamProject(planId, operationIdx)
      .then(async _ => {
        sendEvent("unlinkCamProject", {
          planId,
          operationIdx,
        })
      })
      .catch((error: AxiosError) => {
        errorToast(error, "Unlink CAM Project Error")
      })
  }

  return (
    <>
      <FileUpload
        fileUploadMode={FileUploadMode.CamProjectUpload}
        tooltip={"Drag and drop the CAM project here to back it up. You can also click to select."}
        label={"CAM project"}
        onUpload={file => filesApi.createDocumentFile(planId, operationIdx, file)}
        extensionBlacklist={[".txt", ".nc", ".mpf"]}
      />
      {toolpathProject?.documentFileLocator && (
        <MenuItem onClick={() => unlinkCamProject()} text={"Remove CAM Project"} icon={"trash"} />
      )}
    </>
  )
}

export const VericutProjectUpload: FC<{
  planId: string
  operationIdx: number
}> = ({ planId, operationIdx }) => {
  const { filesApi } = useApi()

  return (
    <FileUpload
      fileUploadMode={FileUploadMode.VericutProjectUpload}
      tooltip={
        "Drag and drop a Vericut project here to update the operation. You can also click to select."
      }
      label={"Vericut project"}
      onUpload={file =>
        filesApi.importVericutProject(planId, operationIdx, file).then(res => {
          console.log(res)
        })
      }
      extensionWhitelist={[".zip"]}
    />
  )
}
